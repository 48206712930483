.carousel .slider-wrapper.axis-horizontal .slider .slide{
  justify-content: center;
  display: flex;
  display: -webkit-flex;
}

.blog-image { 
  width: 100%;
  height: auto;
  max-height:500px;
  object-fit:cover;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .card-title{
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .card-text{
        display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    .map-view-hide{
        display: none !important;
    }
  }

