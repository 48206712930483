@font-face {
    font-family: 'Futura';
    src: url('../fonts/Futura/futuraLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Futura';
    src: url('../fonts/Futura/FuturaBook.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Futura';
    src: url('../fonts/Futura/futuraMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: medium;
    font-display: swap;
}
@font-face {
    font-family: 'Futura';
    src: url('../fonts/Futura/FuturaHeavy.ttf') format('truetype');
    font-weight: 600;
    font-style: medium;
    font-display: swap;
}
@font-face {
    font-family: 'Futura';
    src: url('../fonts/Futura/futuraBold.ttf') format('truetype');
    font-weight: 700;
    font-style: bold;
    font-display: swap;
}
@font-face {
    font-family: 'Futura';
    src: url('../fonts/Futura/FuturaBlack.ttf') format('truetype');
    font-weight: 800;
    font-style: bold;
    font-display: swap;
}


@font-face {
    font-family: 'ProximaNova';
    src: url('../fonts/ProximaNova/ProximaNovaLight.otf') format('opentype');
    font-weight: 300;
    font-style: light;
    font-display: swap;
}
@font-face {
    font-family: 'ProximaNova';
    src: url('../fonts/ProximaNova/ProximaNovaRegular.otf') format('opentype');
    font-weight: 400;
    font-style: light;
    font-display: swap;
}
@font-face {
    font-family: 'ProximaNova';
    src: url('../fonts/ProximaNova/ProximaNovaMedium.otf') format('opentype');
    font-weight: 500;
    font-style: light;
    font-display: swap;
}
@font-face {
    font-family: 'ProximaNova';
    src: url('../fonts/ProximaNova/ProximaNovaBold.otf') format('opentype');
    font-weight: 600;
    font-style: light;
    font-display: swap;
}

@font-face {
    font-family: 'ProximaNova';
    src: url('../fonts/ProximaNova/ProximaNovaBlack.otf') format('opentype');
    font-weight: 800;
    font-style: light;
    font-display: swap;
}


