h4, h5{letter-spacing: 0.4;}
.agencies img{
    max-width: 200px;
    max-height: 50px;
   margin: 15px;
}
@media (max-width: 1399.98px) {
    .agencies{
        flex-wrap: wrap;
        justify-content: center;
    }
} 