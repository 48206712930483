.contact-page h1 {
  color: var(--primary-color);
  font-size: 52px;
  font-weight: 600;
  margin-bottom: 8px;
}
.contact-page p {
  color: var(--text-grey);
  margin: auto;
}
.contact-page .form-label {
  color: var(--primary-color);
}
.form-wrap {
  position: relative;
  padding: 60px;
  border-radius: 20px 20px 0 0;
  min-height: cal(100vh - 20px);
  background-repeat: no-repeat;
  background-size: auto;
}
.form-container {
  margin-left: 52%;
}

.form-wrap::after {
  content: "";
  border-radius: 20px 20px 0px 0px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/contact-bg.png");
  z-index: -1;
  background-repeat: no-repeat;
  background-size: auto;
}

.address-card {
  border: 1px solid #72b2d3;
  width: 350px;
}

.address-bg {
  background-color: var(--light-bg);
  border-radius: 0px 0px 20px 20px;
}
.address-data {
  display: flex;
}
.address-icon {
  align-items: center;
  text-align: center;
}

.scroll-wrap{
  position: relative;

}
.scroll-icon{
  background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 3px 6px 10px #172F5034;
opacity: 1;
height: 50px;
width: 50px;
text-align: center;
border-radius: 50px;
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
  font-size: 18px;
  font-weight: 900;
}
.focus-set input:focus, .focus-set textarea:focus{
  background-color: #fff !important;
  border-color: #005783 !important;
  border: 1px solid !important;
  box-shadow: -1px 0px 7px 0.25rem rgba(0, 87, 131, 0.15) !important;
}
@media (max-width: 1199.98px){
  .form-container{
    margin: 0 auto;
  }
  .address-data{
    display: flex;
    align-items: center;
    justify-content: center !important;
  }
  .form-container{
    z-index: 2;
  }
  .form-wrap::before{
    content:'';
    background-color: rgb(0, 0, 0, 0.2);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .form-container{
    width: 90%;
  }
}

@media (max-width: 1200px) { 
  .contact-page .form-container p{
    color: #fff;
  }
}
@media (max-width: 767.98px) { 

  .form-wrap{
    padding: 10px;
  }

  
  .btn-end{
    display: contents
  }
  .txt-lft-width-sm-devices{
    width: 200px;
    margin: 0;
    font-size: 14px;
}
  }
  
