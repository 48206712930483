
footer{
    background-color: #023047;
    /* position: fixed;
    left:0;
    bottom:0;
    right:0; */
}

footer .nav > .nav-item {
    align-items: center;
    display: flex;
    color: #8098a3;
    font-size: small;
}
.footer-nav{
    flex-wrap: wrap;
}
.footer-nav .navLink{
    padding: 0.25rem 0.7rem;
    text-decoration: none;
    align-items: center;
    display: flex;
    color: white;
    font-size: 16px;
}



.logo {
    padding-top: 0.5rem;
}


.hrColor {
    color: #005682;
}

.nav-item {
    color: #8198a3;
    font-size: small;

}

.icons {
    margin-left:  10px;
    margin-right: 0px;
    color: #FFF;
    height: 30px;
    width: 30px;
}
.footer-nav-2 a{
    color: white;
    padding-right: 0.3rem;
    text-decoration: none;
    font-size: 14px;
}
@media screen and (max-width: 640px) {
    .footer-nav{
       display: flex;
    }
    footer{padding: 20px;}
    .footer-nav-2 a{
        align-items: center;
        color: white;
        padding-right: 0.3rem;
        margin: auto;
        text-decoration: none;
        font-size: small;
    }
    .footer-nav-2 div{
        text-align: center;
    }
    .footer-nav .navLink, 
    .footer-nav > *{
        flex: 1 0 50%; 
        white-space: nowrap;
   }
  .nav-item{text-align: center;}
}

@media screen and (min-width: 700px) and (max-width: 1024px) {
    .logo {
        padding-top: 0.5rem;
        max-width: 90%;
    }
    .footerNav {
        text-align: center ;
        color: white;
        padding-right: 0.3rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: smaller;
        margin: 5px;
    }
    .footerNav a{
        padding: 0.15rem 0.15rem;
        text-decoration: none;
        padding: auto;
        font-size: smaller;
    }
    .nav-item {
        color: #8198a3;
        text-align: center;
        margin-bottom: 10px;
    }
    .footer-nav-2 {
        text-decoration: none;
        max-width: 100%;
        width: auto;
        display: inline-block;
        margin: auto;
        align-items: center;
        text-align: center;
    }
    
    .icons {
        margin-left:  5px;
        margin-right: 0px;
        color: #FFF;
        height: 15px;
        width: 15px;
    }
    .footer-nav .navLink, 
    .footer-nav > *{
        flex: 1 0 31%; 
        white-space: nowrap;
   }
}