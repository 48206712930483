.tag-style-wrap{
    box-shadow: 2px 4px 16px #173c5026;
    border-radius: 45% 45% 4px 4px;
    padding: 20px;
    text-align: center;
    height: 100%;
}
.tag-icon-wrap{
    border-radius: 50%;
    height: 110px;
    width: 110px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 8px 7px 15px #173c5026;
}
.tag-icon-wrap img{
    height: 65px;
}

@media (max-width: 575.98px) { 
    .tag-style-wrap{
       border-radius: 20px;
       display: flex;
       align-items: flex-start;
       text-align: left;
    }
    .tag-icon-wrap{
        height: 80px;
        width: 80px;
        margin-right: 20px;
    }
    .tag-style-wrap h3{margin-top: 0; font-size: 20px;}
    .tag-icon-wrap img{  height: 45px;}
 }
