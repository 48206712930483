.property-search-bar {
  border: 1px solid #93c1d8;
  border-radius: 8px;
  padding: 5px;
  /* padding: 20px 10px; */
  background-color: white;
}
.property-search > * {
  border-right: 1px dashed #ced4da;
}
.property-search > :last-child {
  border-right: none;
}

.dropbtn {
  background-color: #fff;
  color: #818181;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  width: 100%;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
}

.dropdown-content {
  position: absolute;
  background-color: #fff;
  min-width: 310px;
  top: calc(100% + 20px);
  left: -30px;
  box-shadow: 15px 13px 16px #173c5026;
  border: 1px solid #e9f2f7;
  border-radius: 9px;
  z-index: 1050;
}
.dropdown-content .dropdown-title {
  padding: 10px 15px;
  margin-bottom: 0;
  color: var(--primary-color);
  border-bottom: 1px solid #e9f2f7;
}
.rdp-weeknumber,
.rdp-day {
  font-size: 14px;
  font-weight: 600;
}
.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
  color: var(--primary-color);
  opacity: 1;
  background-color: #e3ecf1;
}
.rdp-day_range_start,
.rdp-day_range_end {
  background-color: var(--primary-color);
  color: white;
}

.dropdown-content::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 20px;
  border-bottom: solid 20px rgb(255 255 255);
  border-left: solid 20px transparent;
  border-right: solid 20px transparent;
  top: -12px;
  left: 20px;
}
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #fff;
}

.label {
  color: var(--primary-color);
  display: inline-block;
}
.dropbtn {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropbtn:focus-visible {
border: none;
outline: none;
}
.filters-icon {
  cursor: pointer;
}
.full-width-sm-devices{
  width: 100%;
}

.date-input {
  border: none;
  background-color: transparent;
}

.border-right {
  border-right: 1px dotted var(--text-grey);
}

.date-form {
  margin-left: 15px;
  margin-top: 5px;
}
.date-span:focus-visible {
  border: none;
}
.dropdown-divider {
  margin: -15px 0px 0px 0px;
  padding: 0%;
  color: var(--primary-color);
}

.location-input > div > input {
  padding: 0px;
  min-height: auto;
  border: none;
}
.location-input > div > input:focus {
  padding: 0px;
  min-height: auto;
  color: var(--text-grey);
}

.filter-modal {
  width: auto;
}

@media screen and (max-width: 576px) {

}

@media screen and (max-width: 700px) {

}
@media screen and (max-width: 640px) {
  
  .property-search > * {
    border-right: 0px dashed #ced4da;
  }
}
@media screen and (max-width:768px)
  {
  .bannerRow > *{
    border-right: none !important;
  }
  .border-end{
    border-right: none !important;
  }
}
