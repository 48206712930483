header{
    height: 90px;
   
}
.logo {
   width: 250px;
}

.ff-logo {
    width: 60px;
    height: auto;
    filter: grayscale(1);
 }

.logo-text {
    font-size: small;
  }