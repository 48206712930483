.property-card {
  height: 400px;
}
.primary-color {
  color: var(--primary-color);
}
.price-list::after {
  content: "/month";
  color: #b6bbb6;
  font-size: 12px;
}

.rating-text {
  font-size: 12px;
}

.rating-icon {
  color: #ffd155;
}

.description {
  font-size: 16px;
  color: var(--text-grey);
}

.room-details {
  color: var(--primary-color);
  font-size: 15px;
  font-weight: 500;
}

.room-icons {
  font-size: 20px;
  margin-right: 2px;
}

.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  transition: height .15s ease-in;
  height: 210px;
}

.ph-img {
  width: auto;
  height: 225px;
}

.loader_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #297c785c;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000000;
}

.loader07 {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  top: 50%;
  margin: -8px auto 0;
}


.property-type-container {
  background-color: #228265;
  padding: 2px 15px;
  border-radius: 20px;
  color: #fff;
}

.property-type-condo {
  background-color:#58B754;
  padding: 2px 15px;
  border-radius: 20px;
  color: #fff;
}

.property-type-room {
  background-color:#682282;
  padding: 2px 15px;
  border-radius: 20px;
  color: #fff;
}


.property-type-apartment {
  background-color: var(--primary-color);
  padding: 2px 15px;
  border-radius: 20px;
  color: #fff;
}

.property-type-house {
  background-color:#58B754;
  padding: 2px 15px;
  border-radius: 20px;
  color: #fff;
}
@media screen and (max-width: 576px) {
  .property-card {
    height: auto
  }
}