.homeBanner h1{
    color: var(--primary-color);
    font-size: 52px;
    font-weight: 600;
}
.innerWrap{
    position: relative;
    background-color: var(--light-bg);
    padding: 60px;
    border-radius: 20px 20px;
    min-height: calc(100vh - 190px);
  
    /* overflow: hidden; */
}
.innerWrap::after{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background: url('../../assets/images/banner-background.png') no-repeat;
    background-size: cover;
    background-position: bottom;
    z-index: 0;
    border-radius: 20px;
}

.searchBox{
    position: relative;
    background-color:#fff;
    box-shadow: 30px 23px 27px #173C5034;
    z-index: 1;
    border-radius: 2px;
    margin-top: 20px;
}
.search-mobile-div {
  display: none;
}
.z-index-1 {
  z-index: 1;
}
.error-msg {
  color: #f77373;
}
@media screen and (min-width: 1200px) {
  .innerWrap{
    max-height: 650px;
    min-height:unset;
  }
}
@media (max-width: 575.98px) { 
    .homeBanner h1{font-size: 30px;}
    .innerWrap{
        padding: 30px;
    }
    .innerWrap::after{
        content: unset;
    }
    .search-mobile-div {
      display: block;
    }
 }
/* 
 @media (max-width: 768px) { 
  .homeBanner h1{font-size: 60px;}
  .innerWrap{
      padding: 30px;
  }
  .innerWrap::after{
      content: unset;
  }
  .search-mobile-div {
    display: block;
  }
} */
.dropbtn {
    background-color: #fff;
    color: #818181;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    width: 100%;
 

  }
  .date-form {
    margin-left: 15px;
    margin-top: 5px;
  }
  .date-input {
    border: none;
    background-color: transparent;
  }
  .dropdown-divider {
    margin: -15px 0px 0px 0px;
    padding: 0%;
    color: var(--primary-color);
  }
  .dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
    /* z-index: 1050; */
    justify-content: space-between;
    padding: 15px 30px;
  }
  .bed-ddn .dropdown-content{
    min-width: -webkit-fill-available;
  }
  .dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 315px;
    top: calc(100% + 20px);
    left: 0;
    box-shadow: 7px -5px 28px #173C5026;
    border: 1px solid #E9F2F7;
    border-radius: 9px;
    z-index: 10;
  }
  .dropdown-content .dropdown-title{
    padding: 10px 15px;
    margin-bottom: 0;
    color: var(--primary-color);
    border-bottom: 1px solid #E9F2F7;
  }
  .rdp-weeknumber, .rdp-day{
    font-size: 14px;
    font-weight: 600;
  
  }
  .rdp-day_selected, .rdp-day_selected:focus-visible, .rdp-day_selected:hover {
    color: var(--primary-color);
    opacity: 1;
    background-color: #E3ECF1;
  }
  .rdp-day_range_start, .rdp-day_range_end{
    background-color: var(--primary-color);
    color: white;
  }

  .dropdown-content::before {
    content: '';
    position: absolute;
    width: 30px;
    height: 20px;
    border-bottom: solid 20px rgb(255 255 255);
    border-left: solid 20px transparent;
    border-right: solid 20px transparent;
    top: -12px;
    left: 20px;
  }
  .dropdown-content a:hover {background-color: #f1f1f1}

  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown:hover .dropbtn {
    background-color: #fff;
  }  

  .label {
    color: var(--primary-color);
    display: inline-block;
  }
  .dropbtn{
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  input[type=range]::-webkit-slider-thumb ,   input[type=range]:focus::-webkit-slider-thumb{
    background:var(--primary-color);
    outline: none;
    box-shadow: none;
  }

  input[type=range]:focus::-ms-fill-lower {
    background: var(--primary-color);
  }
  input[type=range]:focus::-ms-fill-upper {
    background:var(--primary-color);
  }

.location-input > div > input {
  padding: 0px;
  min-height: auto;
  border: none;
}
.location-input > div > input:focus {
  padding: 0px;
  min-height: auto;
  color: var(--text-grey);
}

.sm-toast{
  display: none
}
@media (max-width:500px) { 
  .sm-toast{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }
  .toast-all{
    display: none;
  }
}