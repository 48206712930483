.privacy-policy {
    width: 60%;
    margin: 40px auto;
}
.banner-heading{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 94%;
    min-height: calc(30vh);
    background: linear-gradient(90deg, rgba(0,86,130,1) 0%, rgba(0,107,131,1) 100%);    color: #fff;
    margin:0 auto;
    padding: 70px 0;
    border-radius: 20px 20px 0 0;
}
.banner-heading h1{
    color: #fff;
    font-weight: 400;
    margin-bottom: 0;
    font-size: 52px
}
.banner-heading p{
    color: #fff;
}
@media (max-width: 768px){
    .banner-heading{
        padding: 20px 0;
        min-height: auto;
    }
    .banner-heading h1{
        font-size: 35px;
    }
    .banner-heading p{
        font-size: 14px;
    }
    .privacy-policy {
        width: 90%;
    }
}