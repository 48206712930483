.blog-image-main{
  max-height: 300px;
  object-fit: cover;
}

.blog-card-min {
  height: 500px;
}


.blog-wrapper {
  height: calc(100vh - 500px)
}
.blog-container h1 {
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 30px;
}
.nav-pills .nav-link {
  border-radius: 45px;
}

.blog-container a {
  text-decoration: none;
  color: var(--primary-color);
}
.text-link a {
  text-decoration: none;
  color: var(--primary-color);
}

.hr-text {
  position: relative;
  border: none;
  height: 1px;
  background: #999;
}

.hr-text::before {
  content: attr(data-content);
  display: inline-block;
  background: #fff;
  font-weight: bold;
  font-size: 0.85rem;
  color: #999;
  border-radius: 30rem;
  padding: 0.2rem 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 1024px) {
  .blog-details {
    padding: 30px 50px 0px 30px;
  }

  .badge {
    background-color: var(--primary-color);
    padding: 10px 30px;
    font-size: 18px;
    font-weight: 20;
  }

  .description {
    color: var(--primary-color);
  }

  .blog-span {
    color: var(--text-grey);
  }
  .blog-title-min {
    font-size: 22px;
    line-height: 1.4em;
  }
}

.badge-min {
  margin: 15px 0px;
  background-color: var(--primary-color);
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 20;
}

.blog-span {
  color: var(--text-grey);
}

@media screen and (max-width: 900px) {
  .blog-details {
    padding: 0px 0px 0px auto;
  }
  .blog-title {
    padding: auto;
  }

  .badge {
    margin: 15px 0px;
    background-color: var(--primary-color);
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 20;
  }
}
