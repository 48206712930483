.cstm-btn-w-sm-dvices{
    width: 100px;
}
.image{
    background: transparent linear-gradient(180deg, #7F556D00 0%, #5C5757 100%) 0% 0% no-repeat padding-box;
mix-blend-mode: multiply;
border-radius: 10px;
/* z-index: 1; */
}

.image-background{
width: auto;
border-radius: 10px;
}

.overlay {
    position: absolute;
    bottom: 0;
    color: #f1f1f1;
    width: 100%;
    transition: .5s ease;
    opacity:1;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: center;
    left: 2%;
}

.image-background > div{
    position: relative;
    /* width: 200px; */
    /* height: 300px; */
}
.image-background> div .after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #FFF;
}
.image-background> div :hover .after {
    display: block;
    background: rgba(0, 0, 0, .6);
}

.map {
    max-width: 650px;
    width: 100%;
}


@media screen and (min-width:768px) and (max-width:1024px){
    .image-background > div{
        height: 430px;
        padding: 10px;
    }
    .image-background img{
        width: 100%;
        height: inherit;
    }
}
@media screen and (max-width:600px){
    .image-background > div{
        height: 240px;
    }
    .image-background img{
        width: 100%;
        height: 100%;
    }
    .overlay {
        position: absolute;
        bottom: 0;
        color: #f1f1f1;
        width: 100%;
        transition: .5s ease;
        opacity:1;
        color: white;
        font-size: 10px;
        padding: 20px;
        text-align: start;
    }
    
}
@media screen and (max-width:768px){
    /* .image-background > div{
        height: 200px;
    }
    .image-background img{
        width: 100%;
        height: 100%;
    } */
   
}
@media (max-width: 767.98px) { 
    .custom-sm-device-query{
        align-items: center !important;
    flex-direction: column;
    text-align: center;
    }
}

