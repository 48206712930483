.wrap-left {
  background: url("../../assets/images/po-hero.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  padding: 60px;
  height: 100%;
  border-radius: 20px 0 0 0px;
}
.wrap-left .infobox{
  border-radius: 8px;
  overflow: hidden;
  background-color: #D8EBFC;
}
.infobox h4{
  line-height: 1.5em;
  font-size: 26px;
}
.wrap-left .infobox{width: 70% ;}
.wrap-right {
  padding: 60px;
  background-color: #fff;
  border-radius: 0 20px 20px 0;
}
.btn-outline {
  background-color: transparent;
}

.stats-wrap {
  background-color: var(--light-bg);
  padding: 30px;
  border-radius: 10px;
}
.po-step-img img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}
.po-steps {
  counter-reset: step 0;
}
.po-step-wrap P{
margin: 0;display: inline;
}
.expCollapseBtn{
  border: none;
    padding: 0;
    background: transparent;
    font-weight: 500;
    font-size: 16px;
}
.stepCard {
  position: relative;
  margin-left: 35px;
  counter-increment: step 1;
}

.stepCard::before {
  content: counter(step);
  background-color: var(--primary-color);
  height: 35px;
  width: 35px;
  position: absolute;
  top: 0;
  left: -35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.pofaq img {
  object-fit: cover;
  height: 100%;
}
.primary-background {
  background-color: var(--primary-color);
  height: 112px;
  border-radius: 13px 13px 0px 0px;
}
.primary-background > * {
  margin: auto;
  justify-content: center;
  height: 110px;
  width: 110px;
  margin: auto;
  display: flex;
  align-items: center;
}
/* .bg-width-brand-section img {
  width: 100%;
} */
.user-icon-bg {
  background-image: url("../../assets/svg/wavy-circle.svg");
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  height: 50%;
  width: 100%;
  overflow: auto;
  position: absolute;
  top: 8%;
  margin: auto;
  display: flex;
  align-items: center;
}

.pv-card {
  width: 250px;
  height: 260px;
  border-radius: 13px;
  box-shadow: 15px 13px 27px #173c501a;
}

.po-biz {
  background-color: #6DA1BB;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  border-radius: 0px 0px 6px 6px;
}


.po-description {
  margin: 20px 0px;
  font-size: 24px;
  width: 85%;
}

.po-img-wrap {
  background-color: #023047;
  padding: 10px;
  border-radius: 10px 0px 0px 10px;
}

.po-data-wrap {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 15px 13px 27px #173c501a;
  /* width: min-content; */
}

.po-data {
  color: var(--primary-color);
  font-size: 22px;
  line-height: 1.3;
}

.po-select {
  width: 100%;
}

.row2-wrap {
  position: relative;
  border-radius: 20px 20px;
  min-height: calc(60vh);

  /* overflow: hidden; */
}
.row2-wrap::after {
  content: "";
  position: absolute;
  top: 0;
  right: -10%;
  width: 50%;
  height: 100%;
  background: url("../../assets/images/po-sofa.png") no-repeat;
  z-index: 0;
  border-bottom-right-radius: 20px;
}

.data {
  border-radius: 2px;
  margin-top: 20px;
  z-index: 10;
}

.scroll-wrap{
    position: relative;
  
  }
  .scroll-icon{
    background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 3px 6px 10px #172F5034;
  opacity: 1;
  height: 50px;
  width: 50px;
  text-align: center;
  border-radius: 50px;
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
    justify-content: center;
    align-items: center;
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 900;
  }
  
  .focus-set input:focus, textarea:focus{
    background-color: #fff !important;
    border-color: #005783 !important;
    border: 1px solid !important;
    box-shadow: -1px 0px 7px 0.25rem rgba(0, 87, 131, 0.15) !important;
}
.partner-grid{
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  align-items: center;
  padding-left: 0;
}
.partner-grid li{
  width: 25%;
  padding: 10px;
  text-align: center;
}
.partner-grid img{
  max-height: 60px;
}
@media (max-width: 1199.98px) { 
 .row2-wrap::after{
    z-index: -1;
    
  }
  .po-biz img {
    margin: 10px 0;
  }
  .wrap-right {
    padding: 20px;
  }
 }
 @media (max-width: 991.98px) { 
  /* .bg-width-brand-section img{
    width: 70%;
  } */
  .po-description{
    width: 100%;
  }
  .row2-wrap h1{
    text-align: center  ;
  }
  .row2-wrap{
    padding: 20px;
    margin: 20px auto;
  }

  
  
 }
 @media (max-width: 767.98px) { 
  
.wrap-left{
  padding: 20px;
}

.pv-card{
  width: 100%;
  margin: 20px 0;
}
.img-mx-width-sm-devices{
  max-width: 345px;
  margin: 20px auto;
}
.pofaq{
  text-align: center;
}
.SpacingSmDevices{
padding: 20px;
}

.bg-width-brand-section{
  background-color:var(--light-bg);;
  padding: 20px;
  text-align: center;}

.bg-sm-devices h1{
  font-size: 1.8rem
}
.row2-wrap h1 > p{
  font-size: 18px;
  color: #222;
}
.row2-wrap::after{
  right: 0;
  width: 100%;
  opacity: .3;
  z-index: -1;
}
.po-description {
text-align: center;
  padding: 10px;
  width: auto;
}
.po-data-wrap{
  width: auto;
  margin-bottom: 10px;
}
.partner-grid li{
  width: 50%;
  padding: 15px;
}
.wrap-left .infobox{width: 90%;}
.wrap-left .infobox h4{font-size: 24px;}
  }

  @media (min-width: 992px) and (max-width: 1199.98px){

    .img-mx-width-sm-devices{

      width: 262px;
    height: 542px;
    }

  }
  @media (min-width: 320px) and (max-width: 575.98px){

    .pv-card{
 width: 260px;
   margin: 15px auto;
    }
    .img-mx-width-sm-devices{
      max-width: 300px;
    }
    .po-biz img {
      max-width: 190px;
    }
    .shadowbox h4{
      font-size: 16px;
    }
  }
  @media screen and (max-width:767px) {
    .row2-wrap::after {
      display: none;
    }
    
  }
  @media screen and (max-width:991px) {
    .shadowbox{margin: 10px 0;}
  }
  @media screen and (max-width:1200px) {
    .po-biz{
      height: auto;
      flex-wrap: wrap;
      justify-content: center;
    }
    .po-biz img{
      margin: 10px 20px;
      max-width: 100%;
    }
    
  }
  @media screen and (max-width:600px) {
    .sm-hide{
      display: none;
    }
    .industries-wrap .col-md-2{
      width: 50%;
    }
    .po-description{font-size: 18px;}
    .row2-wrap::after{content: unset;} 
    .bg-width-brand-section img{max-height: 60px;}
    .pofaq p{font-size: 20px; line-height: 35px;}
    .pofaq p > br{display: none;}
   
    .w-sm-50{width: 50%;}
  }
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    .shadowbox{margin: 10px;}
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .wrap-left{
      padding: 30px;
    }
    .wrap-left .infobox{width: 100% ;}
    .po-biz{
      height: auto;
      flex-wrap: wrap;
      justify-content: center;
    }
    .po-biz img{
      margin: 10px 20px;
    }
    .pv-card {
      width: 100%;
    }
    .row2-wrap::after {
      display: none;
    }
    .row2-wrap {
      min-height: auto;
    }
   }
