.custom-tab.active {
  background: transparent !important;
  color: white !important;
  border-bottom: 2px solid yellow !important;
  border-radius: 0px !important;
}
.btn-outline {
  --bs-btn-color: #005783;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #005783;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0c4b6b;
  --bs-btn-hover-border-color: ##0c4b6b;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0c4b6b;
  --bs-btn-active-border-color: #0c4b6b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #005783;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #005783;
}

.property-details {
  background-color: #fff;
}
.property-details h3 {
  font-size: 30px;
  font-weight: 600;
  color: var(--primary-color);
}
.property-details h4 {
  font-size: 24px;
  font-weight: 500;
  color: var(--primary-color);
  margin-top: 0;
}

.share-btn {
  border-radius: 45px;
  padding: 6px 20px;
  font-weight: 500;
  margin: 2px 0px 2px 8px;
}

.rating-text {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.rating-icon {
  color: #ffd155;
}

.border-around {
  border: 1px solid #93c1d8;
}
.price-list::after {
  content: "/month";
  color: #b6bbb6;
  font-size: 12px;
}
.price-details {
  justify-content: space-between;
  display: flex;
}
.text-red {
  color: red;
}

.furnished-badge {
  padding: 6px 16px;
  background-color: #48bad548;
  color: var(--primary-color);
  margin: 5px;
  font-size: medium;
  font-weight: 500;
  border-radius: 45px;
}

.property-badge {
  padding: 6px 16px;
  background-color: #ff594b36;
  color: var(--primary-color);
  margin: 5px;
  font-size: medium;
  font-weight: 500;
  border-radius: 45px;
}
.link-data {
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
}

.property-table {
  border: 1px solid #93c1d8;
  border-radius: 10px;
  padding: 30px 30px 10px 30px;
  margin: 20px 0;
}

.scroll-wrap {
  position: relative;
}
.scroll-icon {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 6px 10px #172f5034;
  opacity: 1;
  height: 50px;
  width: 50px;
  text-align: center;
  border-radius: 50px;
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
  font-size: 18px;
  font-weight: 900;
}

.profile-wrapper {
  border-radius: 50px;
  font-size: large;
  width: 59px;
  height: 59px;
  border: 1px solid #93c1d8;
}

.review-btn {
  font-size: 20px;
}

.content-heading {
  font-size: 26px;
  padding: 5px;
}
.content-heading-icon {
  font-size: 26px;
  color: #ffd055;
}

.pd-nav.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--text-grey);
  text-decoration: underline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.pd-nav-link {
  color: var(--text-grey);
}
.nav-link.active {
  color: var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
}
.carousel .thumbs {
  padding-left: 0;
}

@media screen and (max-width: 700px) {
  .content-heading {
    font-size: 16px;
    padding: 5px;
  }
  .content-heading-icon {
    font-size: 16px;
    color: #ffd055;
  }
  .review-btn {
    font-size: 16px;
  }
  .link-data {
    display: block;
  }
  .carousel-root .slider-wrapper {
    height: 230px;
  }
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.popup-content {
  display: flex;
  align-items: center;
}

.close-button {
  position: absolute;
  top: 30px;
  left: 30px;
  background-color: transparent;
  border: none;
  display: flex;
}
.pageDiv {
  position: absolute;
  top: 30px;
  background-color: transparent;
  border: none;
}
.md-close-btn {
  height: 20px;
  width: 20px;
  color: white;
  margin-top: 4px;
  margin-right: 4px;
}
/* .modalImg {
  max-height: 100%;
  max-width: 700px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.contentCarousel {
  max-width: 900px;
  display: flex;
  align-items: center;
  margin: auto;
  max-height: 560px;
  object-fit: contain;
}
.contentCarousel .carousel .slide{
  justify-content: center;
}
.carousel .slider-wrapper.axis-horizontal .slider .slide{
  justify-content: center;
  display: flex;
}
.amenities-sp {
	width:32px;
	object-fit: contain;
	max-height:40px
}

.amenities-img {
  object-fit: contain;
  max-height: inherit;
  width: 100%;
}