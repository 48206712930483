.learn-more {
  background: var(--light-bg);
  border-radius: var(--section-radius);
}
.learn-more .lm-card{
  border-radius: 30px;
  padding: 30px 20px;
  border: none;
}
.learn-more-btn{
  margin-top: -20px;
}
.lm-card .icon{
  width: auto;
  height: 150px;
  margin-bottom: 20px;
}

.card-body{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
}

.icon {
  width: 170px;
  height: 140px;
  padding-top: 15px;
margin: auto;
}

.card-title {
    color: var(--primary-color);
    font-size: 22px; 
}

.card-description {
    color: var(--text-grey);
    font-size: 17px; 

}
.learn-more-wrap{
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.learn-more-btn{
    margin-top: -18px;
    z-index: 1;
    background-color: #FFF;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.learn-more-btn:hover{
    margin-top: -18px;
    z-index: 1;
    color: #FFF;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.learn-more-btn:focus{
    color: #FFF;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow);
}
@media screen  and (max-width:1024px){
  .learn-more .lm-card img{
    height: 85px;
    margin-bottom: 8px;
  }
  .card-title{font-size: 20px;}
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .card-title{
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
 }