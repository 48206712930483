.property-details-link {
  text-decoration: none;
}

.card-new-york {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/images/c_new_york.jpeg");
  background-size: cover;
}

.card-san-diego {
  background-image: url("../../assets/images/c_san_diego.jpeg");
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}

.card-los-angeles {
  background-image: url("../../assets/images/c_los_angeles.jpeg");
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}

.card-chicago {
  background-image: url("../../assets/images/c_chicago.jpeg");
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}

.card-boston {
  background-image: url("../../assets/images/c_boston.jpeg");
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}

.card-seattle {
  background-image: url("../../assets/images/c_seattle.jpeg");
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  
}

.country-card {
  height: 200px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: center;
  text-align: center;
  font-size: xx-large;
  border-radius: 10px;
  border: 0px;
  cursor: pointer;
}

.country-card::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 10px;

}

.country-card h1 {
  position: relative;
  color: #fff;
  /* font-size: 40px; */
  font-weight: 700;
  margin-bottom: 0;
  line-height: 0.9;
  text-align: center;
}
.map-column>div{
  position: sticky;
  top:10px;
  height: 100vh;
}
.mrk-container {
  background: white;
  border: '1px solid #ccc';
  padding: 5;
  display: table;
  color: white;
  border: 1px solid black;
  border-radius: 15px;
  left: -50%;
  box-shadow: rgb(0 0 0 / 8%) 0 0 0 1px, rgb(0 0 0 / 18%) 0 1px 2px;
}
.mrk-container:hover {
  z-index: 2;
}
.anime {
  position: relative;
  cursor: pointer;
  transform-origin: 50% 50%;
  transition: transform .15s ease 0s;
  height: 24px;
  transform: scale(1);
}
.search-title {
  color: white;
  position: relative;
  z-index: 9;
  line-height: 3rem;
  margin-bottom: 20px;
}
.search-box {
  color: white;
  margin: auto;
  max-width: 900px;
  position: relative;
  z-index:9;
}

.search-landing {
  background-image: url("../../assets/images/search-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 120px 40px;
  min-height: calc(100vh - 100px);
  border-radius: 10px;
  margin-top: 0 !important;
  position: relative;
}
.search-landing:after{
  content: '';
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.scroll-wrap{
  position: relative;

}

.scroll-icon{
  background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 3px 6px 10px #172F5034;
  opacity: 1;
  height: 50px;
  width: 50px;
  text-align: center;
  border-radius: 50px;
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
  font-size: 18px;
  font-weight: 900;
}

.filter-badge {
  padding: 6px 16px;
  background-color: #0057831a;
  color: var(--primary-color);
  margin: 5px;
  font-size: medium;
  font-weight: 500;
  border-radius: 45px;
}

.view-btn {
  background-color: var(--primary-color);
  border: none;
  border-radius: 25px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  position: fixed;
  left: 50%;
  text-align: center;
  bottom: 50px;
  text-align: center;
  text-decoration: none;
  transform: translateX(-50%);
  z-index: 1000;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}
.view-btn i{
  font-size: 40px;
}
.view-btn svg{
  margin-right: 8px;
}
/* Change the background color on hover */
.view-btn:hover {
  background-color: var(--primary-color);
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .search-landing {
    min-height: calc(100vh - 300px);
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
  }
  .search-box {
    margin: 0;
  }
  
}
@media screen and (max-width:768px){
  .search-title {
    line-height: 2rem;
    margin-bottom: 25px;
  }
  .content-column h2 span{
    display: block;
  }
}
@media (max-width: 991.98px) {

  .search-landing{
    background-size: cover;
  }

}