.blog-left-panel {
  background-color: rgb(0 0 0 / 3%);;
  border-radius: 4px;
  padding: 25px 35px;
}

.blog-right-panel {
    padding: 0px 0px 0px 35px;
  }
  

.blog-left-panel h2 {
  font-size: 26px;
}
  .parent-container {
    height: 100vh; /* Adjust this value based on your needs */
    overflow: hidden;
  }
  
  .scrollable {
    overflow-y: auto;
    height: 100vh; /* Ensure it takes the full viewport height */
  }
  
  .fixed {
    position: sticky;
    top: 0;
    height: 100vh; /* Ensure it takes the full viewport height */
    background-color: #f7f7f7; /* Optional background color to differentiate between columns */
  }
  
  .latest-column>div{
    position: sticky;
    top:10px;
    height: 100vh;
  }